<template>
  <main class="translation page_bg_grey blank-aside-js" :class = "(isOpenAside=='true')?'blank-aside':''">
    <p class="lang-title">{{ $t("Manage Translation") }}</p>
    <div class="table-content-part">
      <b-row>
        <b-col sm="6">
          <div class="establish-info__item">
            <label class="label establish-info__label">{{ $t("Group") }}</label>
            <b-form-input
              v-model="$v.addForm.group.$model"
              :state="validateState('group')"
              aria-describedby="input-group-feedback"
              class="input"
            ></b-form-input>
            <b-form-invalid-feedback id="input-group-feedback">{{ $t("This is a required field.") }}</b-form-invalid-feedback>
          </div>
        </b-col>
        <b-col sm="6">
          <div class="establish-info__item">
            <label class="label establish-info__label">{{ $t("Key") }}</label>
            <b-form-input
              v-model="$v.addForm.key.$model"
              :state="validateState('key')"
              aria-describedby="input-key-feedback"
              class="input"
            ></b-form-input>
            <b-form-invalid-feedback id="input-key-feedback">{{ $t("This is a required field.") }}</b-form-invalid-feedback>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="4">
          <div class="establish-info__item">
            <label class="label establish-info__label">{{ $t("English") }}</label>
            <b-form-input
              v-model="$v.addForm.en.$model"
              :state="validateState('en')"
              aria-describedby="input-en-feedback"
              class="input"
            ></b-form-input>
            <b-form-invalid-feedback id="input-en-feedback">{{ $t("This is a required field.") }}</b-form-invalid-feedback>
          </div>
        </b-col>
        <b-col sm="4">
          <div class="establish-info__item">
            <label class="label establish-info__label">{{ $t("Hebrew") }}</label>
            <b-form-input
              v-model="$v.addForm.he.$model"
              :state="validateState('he')"
              aria-describedby="input-he-feedback"
              class="input"
            ></b-form-input>
            <b-form-invalid-feedback id="input-he-feedback">{{ $t("This is a required field.") }}</b-form-invalid-feedback>
          </div>
        </b-col>
        <b-col sm="4">
          <div class="establish-info__item">
            <label class="label establish-info__label">{{ $t("Arabic") }}</label>
            <b-form-input
              v-model="$v.addForm.ar.$model"
              :state="validateState('ar')"
              aria-describedby="input-ar-feedback"
              class="input"
            ></b-form-input>
            <b-form-invalid-feedback id="input-ar-feedback">{{ $t("This is a required field.") }}</b-form-invalid-feedback>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="4">
          <a
            class="link establish-info__link color-white m-0"
            @click="addLang"
          >{{ $t("Add") }}</a>
        </b-col>
      </b-row>
    </div>
    <div class="table-content-part">
      <div class="search-bar">
        <p class="search-label">{{ $t("Search") }}</p>
        <v-text-field v-model="search" single-line hide-details class="search-form"></v-text-field>
      </div>
      <v-data-table
        :headers="headers"
        :items="lang_data"
        class="cus-table editable-table translation-table"
        :search="search"
      >
        <template v-slot:[`item.group`]="{ item }">
          <input class="input" type="text" v-model="item.group" />
        </template>
        <template v-slot:[`item.key`]="{ item }">
          <input class="input" type="text" v-model="item.key" />
        </template>
        <template v-slot:[`item.en`]="{ item }">
          <input class="input" type="text" v-model="item.en" />
        </template>
        <template v-slot:[`item.he`]="{ item }">
          <input class="input" type="text" v-model="item.he" />
        </template>
        <template v-slot:[`item.ar`]="{ item }">
          <input class="input" type="text" v-model="item.ar" />
        </template>
        <template v-slot:[`item.save_active`]="{ item }">
          <v-icon medium color="dark" @click="updateLang(item)">edit</v-icon>
          <v-icon medium color="dark" @click="delOneLang(item.id)">delete</v-icon>
        </template>
      </v-data-table>
    </div>
  </main>
</template>

<script>
import "bootstrap";
import "../common/plugins/bootstrap-vue";
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { mapState, mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],
  name: "ManageTranslation",
  data() {
    return {
      search: "",
      visible: false,
      headers: [
        {
          text: this.$t("Group"),
          align: "center ",
          sortable: false,
          value: "group"
        },
        {
          text: this.$t("Key"),
          align: "center ",
          sortable: false,
          value: "key"
        },
        {
          text: this.$t("English"),
          align: "center ",
          sortable: false,
          value: "en"
        },
        {
          text: this.$t("Hebrew"),
          align: "center ",
          sortable: false,
          value: "he"
        },
        {
          text: this.$t("Arabic"),
          align: "center ",
          sortable: false,
          value: "ar"
        },
        {
          text: "",
          align: "center ",
          sortable: false,
          value: "save_active"
        }
      ],
      addForm: {
        group: "",
        key: "",
        en: "",
        he: "",
        ar: ""
      }
    };
  },
  validations: {
    addForm: {
      group: {
        required
      },
      key: {
        required
      },
      en: {
        required
      },
      he: {
        required
      },
      ar: {
        required
      }
    }
  },
  computed: {
    ...mapState({
      lang_data: state => state.language.langData_receipts,
      loading: state => state.language.loading
    })
  },
  async created() {
    if (this.lang_data == "") {
      await this.getLangData();
    }
  },
  methods: {
    ...mapActions("language", {
      getLangData: "getAllData",
      addLangData: "addLangData",
      delLangData: "delLangData",
      updateLangData: "updateLangData"
    }),

    /* -----validation---- */
    validateState(name) {
      const { $dirty, $error } = this.$v.addForm[name];
      // debugger;
      return $dirty ? !$error : null;
    },

    /* add one language data */
    addLang() {
      this.$v.addForm.$touch();
      if (
        this.$v.addForm.group.$anyError ||
        this.$v.addForm.key.$anyError ||
        this.$v.addForm.en.$anyError ||
        this.$v.addForm.he.$anyError ||
        this.$v.addForm.ar.$anyError
      ) {
        return;
      }
      this.addLangData({
        group: this.addForm.group,
        key: this.addForm.key,
        en: this.addForm.en,
        he: this.addForm.he,
        ar: this.addForm.ar
      });
      // this.reset_addForm();
      this.$v.$reset();
    },
    /*  */

    /* update one language data */
    updateLang(item) {
      this.updateLangData({
        id: item.id,
        group: item.group,
        key: item.key,
        en: item.en,
        he: item.he,
        ar: item.ar
      });
    },
    /*  */

    reset_addForm() {
      this.addForm.group = "";
      this.addForm.key = "";
      this.addForm.en = "";
      this.addForm.he = "";
      this.addForm.ar = "";
    },
    /*  */

    /* remove one language data */
    delOneLang(id) {
      this.delLangData({ id: id });
    }
  }
};
</script>

<style scoped lang="scss">
.translation {
  height: 100%;
  color: #002036;
}

.lang-title {
  font-size: 30px;
  margin-top: 10px;
  text-align: center;
}

.input {
  width: 100%;
  font-family: "Open Sans Hebrew";
  font-size: 18px;
  color: #002036;
  outline: 0;
  height: 44px;
  line-height: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #b2bbc2;
  border-radius: 0;
}
.input:focus {
  box-shadow: none;
}

.input.is-invalid {
  border-color: #dc3545;
}
</style>